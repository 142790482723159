import { motion } from 'framer-motion';
import { pageSlideOutForward } from '../../framer-animations/page-slide-in-out';
import { useState } from 'react';
import '../../styles/pages/forgotPassword.scss';
import { useNavigate } from 'react-router-dom';
import { applicationWWWXRLEncodedBodyBuilder } from '../../lib/utils/body-builder';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
import { useTranslation } from 'react-i18next';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import { RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import RenderLoginPageLogo from '../login/RenderLoginPageLogo';
import { submitLogToBugsnag } from '../../lib/api/log';
import AINormalInput from '../../ui/inputs/AINormalInput';
import { updateProfile } from '../../store/reducers/userSlice';
import { FORGOT_PASSWORD_ENDPOINT } from '../../lib/constants/central-api';
import VerifyOTP from './VerifyOTP';
import useTitle from '../../customHooks/useTitle';
import adUpLogoDark from '../../assets/adup_merchant_logo_dark.png';
import adUpLogoLight from '../../assets/adup_merchant_logo_light.png';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  useTitle(t('ForgotPassword'));
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const isDarkMode = useSelector((state: RootState) => state.UI.theme.dark);
  const isDisabled = email === '' ? true : false;
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  async function onSubmitHandler(e: any) {
    e.preventDefault();
    setLoading(true);
    try {
      const requestInfo: RequestInfo = {
        url: FORGOT_PASSWORD_ENDPOINT,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder({
          email: email,
        }),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
        },
      };

      const actionType = 'FORGOT_PASSWORD';
      const customMsg = t('ForgotPasswordSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        setIsEmailSent(true);
        setLoading(false);
        dispatch(
          updateProfile({
            email: email,
          })
        );
      } else {
        setLoading(false);
      }
    } catch (exception) {
      setLoading(false);
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  const forgotPasswordView = () => {
    return (
      <motion.div {...pageSlideOutForward} className='forgotPassword'>
        <form onSubmit={onSubmitHandler} className='forgotPassword__left'>
          <div className='forgotPassword__logo'>
            <RenderLoginPageLogo
              staticURL={staticURL}
              merchantTheme={merchantTheme}
              isDarkMode={isDarkMode}
            />
          </div>
          <div className='forgotPassword__content'>
            <div className='forgotPassword__left--top'>
              <h1>{t('ForgotPassword')}</h1>
            </div>
            <div className='forgotPassword__left--center'>
              <AINormalInput
                id='forgot-password-form-email'
                value={email}
                type='email'
                changeListeners={[(e) => setEmail(e.target.value)]}
                focusListeners={[]}
                placeholder={t('EnterYourEmailAddress')}
                innerPlaceholder={'you@example.com'}
                required={true}
                pattern='^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$'
                title={t('Email-validation')}
              />
            </div>
            <div className='forgotPassword__left--bottom'>
              <div className='forgotPassword__left--bottom-button'>
                <MainSquareButton
                  value={t('SendResetLink')}
                  type='submit'
                  disabled={isDisabled}
                  classes='mainSquareButton-extra-1'
                  loading={loading}
                />
              </div>

              <div className='forgotPassword__left--bottom-links'>
                <h3>{t('SentResetLinkToYourEmail')}</h3>
                <h2>
                  {t('Or')}{' '}
                  <span onClick={() => navigate('/login')}>{t('SignIn')} </span>
                  {t('YourAccount')}
                </h2>
              </div>
            </div>
          </div>
        </form>

        <div className='forgotPassword__right'>
          <div className='forgotPassword__right--image'>
            {merchantTheme?.merchant_logo_light &&
            merchantTheme?.merchant_logo_light !== '' ? (
              <img
                src={`${staticURL}/medium/${
                  isDarkMode
                    ? merchantTheme?.merchant_logo_light
                    : merchantTheme?.merchant_logo_dark
                }`}
                alt={'logo'}
              />
            ) : (
              <img
                src={isDarkMode ? adUpLogoLight : adUpLogoDark}
                alt={'logo'}
              />
            )}
          </div>
        </div>
      </motion.div>
    );
  };

  if (isEmailSent) {
    return <VerifyOTP />;
  } else {
    return forgotPasswordView();
  }
};

export default ForgotPassword;
