import { t } from 'i18next';
import moment from 'moment';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { setMessage } from '../../store/reducers/appSlice';
import store from '../../store/store';
import generateErrorMessage from '../helper-functions/generateErrorMessage';
import { tokenRevokeAtLogOutUser } from './logout-user';
import { submitLogToBugsnag } from './log';
import makeFirstLetterCapital from '../helper-functions/makeFirstLetterCapital';
import { APP_ENV } from '../constants/central-api';
import localStorageHardReset from '../helper-functions/localStorageHardReset';

export const asyncHandler = async (
  requestInfo: RequestInfo,
  actionType: string,
  customMsg = t('Success'),
  shouldShowMsg = true,
  ignore401ForceLogOut = false,
  shouldShowErrorMsg = false
) => {
  const accessToken = store.getState().auth.authorization.accessToken;
  // method,headers,body,token,url
  if (!requestInfo.method || !actionType) {
    console.log('Required parameters are missing!');
    return null;
  }

  if (!requestInfo.headers) {
    requestInfo.headers = {};
  }

  /* --------------Token expiry check Start---------------- */
  if (accessToken?.expiresOn && accessToken?.expiresOn !== null) {
    if (moment(accessToken?.expiresOn) < moment()) {
      logoutHandler();
      return null;
    }
  }
  /* --------------Token expiry check End---------------- */

  try {
    const response = await fetch(requestInfo.url, {
      method: requestInfo.method,
      headers: requestInfo.headers,
      body: requestInfo.body || null,
    });

    const triggerUnauthorizedAccessToast = () => {
      store.dispatch(
        setMessage({
          message: t('UnauthorizedAccess'),
          messageType: 'error',
        })
      );
    };

    if (response?.status === 401) {
      const isWhiteLabel = store.getState().UI.isWhiteLabel;

      if (!ignore401ForceLogOut) {
        localStorageHardReset();

        if (isWhiteLabel) {
          window.location.assign('/authorize');
        } else {
          triggerUnauthorizedAccessToast();
        }
      }
    }

    if (response?.status === 204) {
      return { data: null, actionType };
    }

    const data = await response.json();
    console.log(actionType);
    console.log(data);

    if (data) {
      let message, messageType;
      if (data.success === true && data.messages?.length > 0) {
        message = data.messages;
        messageType = 'success';
      } else if (
        data.success === true &&
        Object.values(data.messages ? data.messages : {}).length > 0
      ) {
        message = Object.values(data.messages).join(`, `);
        messageType = 'success';
      } else if (data.success === true) {
        message = customMsg;
        messageType = 'success';
      } else if (data.success === false && response?.status === 422) {
        console.log(generateErrorMessage(data));
        message = generateErrorMessage(data);
        messageType = 'warning';
        shouldShowMsg = true;
        submitLogToBugsnag('error', generateErrorMessage(data));
      } else if (data.success === false) {
        console.log(generateErrorMessage(data));
        message = generateErrorMessage(data);
        messageType = 'error';
        if (shouldShowErrorMsg) {
          shouldShowMsg = true;
        }
        submitLogToBugsnag('error', generateErrorMessage(data));
      } else if (data.message === 'Unauthenticated.') {
        logoutHandler();
        return null;
      } else {
        const errorActionType = makeFirstLetterCapital(
          actionType?.replaceAll('_', ' ')
        );
        submitLogToBugsnag(
          'error',
          `Something went wrong in ${errorActionType} request.`
        );
        throw new Error('Something went wrong');
      }

      /* console.log(shouldShowMsg); */
      if (shouldShowMsg) {
        store.dispatch(setMessage({ message, messageType }));
      }

      return { data, actionType };
    }
  } catch (err: any) {
    console.log(err.message);
    submitLogToBugsnag('error', ` ${err?.message ?? err}`);
    if (shouldShowMsg) {
      store.dispatch(
        setMessage({ message: err.message, messageType: 'error' })
      );
    }
    return null;
  }
};

const logoutHandler = () => {
  console.log('❌ Access token expired. Logging Out user');
  store.dispatch(
    setMessage({
      message: t('SessionExpired'),
      messageType: 'error',
    })
  );
  tokenRevokeAtLogOutUser();
  if (APP_ENV === 'LIVE') {
    // @ts-ignore:next-line
    window.Intercom && window.Intercom('shutdown'); //shutdown intercom
  }

  localStorageHardReset();
};
