import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import get_graph_data_api_call from '../../../lib/api/stats/get_graph_data_api_call';
import OverallCTRBarChart from './OverallCTRBarChart';
import '../../../styles/components/charts/pieChartComponent.scss';
import ChartWithConfig from '../ChartWithConfig';
import IndividualCTRBarChart from './IndividualCTRBarChart';
import isNotEmptyString from '../../../lib/helper-functions/isNotEmptyString';
import capitalizeFirstLetterOfEachWord from '../../../lib/helper-functions/capitalizeFirstLetterOfEachWord';

type Props = {
  timeFilter: string;
  customPeriodFilterDateRange: {
    start_date: string | undefined;
    end_date: string | undefined;
  };
  eventFilter: string;
};

const MainCTRChart = ({
  timeFilter = 'all',
  customPeriodFilterDateRange,
  eventFilter = 'purchase',
}: Props) => {
  const { t } = useTranslation();
  const stats = useSelector((state: RootState) => state.user?.stats);
  const [params, setParams] = useState<string>(
    `?graph_type=ctr_graph&group_by=overall&time_range=${timeFilter}&event_type=${eventFilter}`
  );
  const [selectedElement, setSelectedElement] = useState<string>('');
  const [response, setResponse] = useState<any>('initial');
  const [isBackButtonVisible, setIsBackButtonVisible] =
    useState<boolean>(false);

  const [title, setTitle] = useState<string>(t('ClickThroughRate'));
  const [comment, setComment] = useState<string>(t('OverallClickThroughRate'));

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    try {
      // Call to get conversion rate stats by location
      const res = await get_graph_data_api_call(
        stats.ai_tracker_api_key,
        stats.ai_tracker_access_token,
        params
      );

      if (res) {
        if (selectedElement !== '') {
          setResponse({
            type: 'individual',
            data: res,
          });
        } else {
          setResponse({ type: 'overall', data: res });
        }
      } else {
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
    }
  };

  useEffect(() => {
    let dateRangeURLParam = '';
    if (timeFilter === 'custom') {
      const { start_date, end_date } = customPeriodFilterDateRange || {};
      if (start_date === undefined || end_date === undefined) {
        return;
      }
      dateRangeURLParam = `&start_date=${start_date}&end_date=${end_date}`;
    }

    let groupByURLParam = '';
    if (isNotEmptyString(selectedElement)) {
      groupByURLParam = `&group_by=individual&group_by_value=${selectedElement}`;
      setComment(`${t('ClickThroughRateOf')} ${selectedElement}`);
    } else {
      groupByURLParam = `&group_by=overall`;
      setComment(t('OverallClickThroughRate'));
    }

    let eventURLParam = '';
    if (isNotEmptyString(eventFilter)) {
      eventURLParam = `&event_type=${eventFilter}`;
    }

    setParams(
      `?graph_type=ctr_graph&time_range=${timeFilter}${dateRangeURLParam}${groupByURLParam}${eventURLParam}`
    );
  }, [selectedElement, timeFilter, customPeriodFilterDateRange, eventFilter]);

  useEffect(() => {
    if (
      stats.ai_tracker_api_key !== '' &&
      stats.ai_tracker_access_token !== ''
    ) {
      setResponse('initial');
      getGraphData();
    }
  }, [params, stats.ai_tracker_access_token, stats.ai_tracker_api_key]);

  const onBackButtonClick = () => {
    if (selectedElement !== '') {
      setSelectedElement('');
    }
  };

  useEffect(() => {
    if (selectedElement !== '' && eventFilter === 'purchase') {
      setIsBackButtonVisible(true);
    } else {
      setIsBackButtonVisible(false);
    }
  }, [selectedElement]);

  useEffect(() => {
    if (eventFilter === 'add_to_cart') {
      setSelectedElement('addToCartButtonElement');
      setTitle(
        capitalizeFirstLetterOfEachWord(
          `${t('ColorBased')} ${eventFilter} ${t('ClickThroughRate')}`
        )
      );
      setComment('');
    } else if (eventFilter === 'pay_button') {
      setSelectedElement('payButtonElement');
      setTitle(
        capitalizeFirstLetterOfEachWord(
          `${t('ColorBased')} ${eventFilter} ${t('ClickThroughRate')}`
        )
      );
      setComment('');
    } else if (eventFilter === 'go_to_checkout') {
      setSelectedElement('goToCheckoutButtonElement');
      setTitle(
        capitalizeFirstLetterOfEachWord(
          `${t('ColorBased')} ${eventFilter} ${t('ClickThroughRate')}`
        )
      );
      setComment('');
    } else {
      setSelectedElement('');
      setTitle(t('ClickThroughRate'));
    }
  }, [eventFilter]);

  function renderChart() {
    if (selectedElement === '') {
      return (
        <OverallCTRBarChart
          apiResponse={response}
          setSelectedElement={setSelectedElement}
        />
      );
    } else {
      return <IndividualCTRBarChart apiResponse={response} />;
    }
  }

  return (
    <ChartWithConfig
      title={title}
      comment={comment}
      onBackButtonClick={onBackButtonClick}
      isBackButtonVisible={isBackButtonVisible}
      isConfigHidden={true}
    >
      {renderChart()}
    </ChartWithConfig>
  );
};

export default MainCTRChart;
