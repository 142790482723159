import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { CodeSection } from 'react-code-section-lib';
import { clockSVG } from '../../../../lib/allCustomSVGs';
import { useState } from 'react';
import {
  regularScriptCode,
  shopifyPixelCode,
} from '../../../../lib/constants/integration_script_code';
import TrackerSwitch from './TrackerSwitch';
import AINormalInput from '../../../../ui/inputs/AINormalInput';
import AISelectInput from '../../../../ui/inputs/AISelectInput';
import '../../../../styles/pages/checkoutTracking/trackingIntegration.scss';
import StatusCard from '../../../../ui/cards/StatusCard';

interface Props {
  apiKey: any;
  isTrackerON: boolean;
  setIsTrackerON: (value: boolean) => void;
  isAutofillEnabled: boolean;
  data: any;
  setAPIData: (data: any) => void;
  setScriptKey: (key: string) => void;
}

function Integration({
  apiKey,
  isTrackerON,
  setIsTrackerON,
  isAutofillEnabled,
  data,
  setAPIData,
  setScriptKey,
}: Props) {
  const { t } = useTranslation();
  const isAppThemeDark = useSelector((state: RootState) => state.UI.theme.dark);
  /*   const [isShopifyIntegration, setIsShopifyIntegration] =
    useState<boolean>(false); */

  const companyDetails = useSelector((state: RootState) => state.user.company);

  const [shopURL, setShopURL] = useState(companyDetails?.webshop_url ?? '');
  const [detectedCMS, setDetectedCMS] = useState('custom');

  const detectCMS_Arr = [
    { OptionValue: 'custom', OptionName: 'Custom' },
    { OptionValue: 'shopify', OptionName: 'Shopify' },
  ];

  console.log(data);

  const renderIntegrationCode = () => {
    return (
      <div style={{ paddingTop: 0 }} className='normalForm'>
        <h1 className='trackingIntegration-title'>{t('IntegrationSetup')}</h1>
        <span className='trackingIntegration-subtitle'>
          {t('FollowTheseStepsToIntegrateTrackingOnYourWebsite')}
        </span>
        <div style={{ marginTop: '2rem' }} className='normalForm__top'>
          {/* //* ----------- RegularIntegration -- Start ----------- */}
          {detectedCMS === 'custom' && (
            <>
              <span className='trackingIntegration-subtitle-2'>
                {t('CheckoutTrackingIntegrationInstructions1')}
              </span>
              <div className='trackingIntegration-subtitle'>
                {t('CheckoutTrackingIntegrationInstructions2')} {`<head>`}
              </div>

              <div className='code__snippet'>
                <CodeSection
                  showLineNumbers={true}
                  theme={'dark'}
                  lang={'html'}
                >
                  {regularScriptCode(apiKey)}
                </CodeSection>
              </div>
            </>
          )}
          {/* //* ----------- RegularIntegration -- End ----------- */}

          {/* //* ----------- ShopifyIntegration -- Start ----------- */}
          {detectedCMS === 'shopify' && (
            <>
              <span className='trackingIntegration-subtitle-2'>
                {t('CheckoutTrackingIntegrationInstructions0-Shopify')}
              </span>
              <div
                style={{ marginBottom: '1rem' }}
                className='trackingIntegration-subtitle'
              >
                {t('CheckoutTrackingIntegrationInstructions1-Shopify')}
              </div>
              <ul className='trackingIntegration-list'>
                <li>{t('CheckoutTrackingIntegrationInstructions2-Shopify')}</li>
                <li>{t('CheckoutTrackingIntegrationInstructions3-Shopify')}</li>
                <li>{t('CheckoutTrackingIntegrationInstructions4-Shopify')}</li>
                <li>{t('CheckoutTrackingIntegrationInstructions5-Shopify')}</li>
                <li>{t('CheckoutTrackingIntegrationInstructions6-Shopify')}</li>
              </ul>

              <div className='code__snippet'>
                <CodeSection
                  showLineNumbers={true}
                  theme={'dark'}
                  lang={'html'}
                >
                  {shopifyPixelCode(apiKey)}
                </CodeSection>
              </div>
            </>
          )}
          {/* //* ----------- ShopifyIntegration -- End ----------- */}
        </div>
      </div>
    );
  };

  return (
    <div className='trackingIntegration'>
      {/* ======================================================================= */}
      <div className='trackingIntegration-wrapper'>
        <h1 className='trackingIntegration-title'>{t('IntegrationStatus')}</h1>
        <span className='trackingIntegration-subtitle'>
          {t('OverviewOfYourIntegrationPerformance')}.
        </span>
        <div className='trackingIntegration-status'>
          <StatusCard
            title={t('LastRequest')}
            info={data?.integration_stats?.recent_request_age ?? ''}
            description={t('LastTrackingRequestReceived')}
            icon={clockSVG}
          />
          <StatusCard
            title={t('IntegrationAge')}
            info={
              data?.integration_stats?.age
                ? data?.integration_stats?.age?.replace(' ago', '')
                : ''
            }
            description={t('SinceFirstIntegration')}
            icon={clockSVG}
          />
          <StatusCard
            title={t('RequestsToday')}
            info={data?.integration_stats?.today_request_counts ?? 0}
            description={t('TrackingRequestsReceivedToday')}
            icon={clockSVG}
          />
          <StatusCard
            title={t('Status')}
            info={
              data?.integration_stats?.status === 'Active'
                ? t('Active')
                : t('Inactive')
            }
            description={
              data?.integration_stats?.status === 'Active'
                ? t('IntegrationIsWorkingProperly')
                : t('IntegrationError')
            }
            color={true ? 'green' : ''}
            icon={clockSVG}
          />
        </div>
      </div>
      {/* ======================================================================= */}
      <div
        style={{ marginTop: '2rem' }}
        className='trackingIntegration-wrapper'
      >
        <div style={{ paddingTop: 0 }} className='normalForm'>
          <h1 className='trackingIntegration-title'>{t('BasicSetup')}</h1>
          <span className='trackingIntegration-subtitle'>
            {t('ConfigureYourWebshopIntegration')}
          </span>
          <div className='normalForm__top'>
            <div className='normalForm__container1'>
              <div className='normalForm__container1--left'>
                <AINormalInput
                  changeListeners={[(e) => setShopURL(e.target.value)]}
                  id='integration__webshop-url'
                  value={shopURL}
                  placeholder={t('WebshopURL')}
                  required={true}
                  // title={'URL must follow the format: https://your-webshop.com'}
                  innerPlaceholder={'https://your-webshop.com'}
                  readonly={true}
                />
              </div>

              <div className='normalForm__container1--right'>
                <AISelectInput
                  defaultValue={detectedCMS}
                  setSelect={setDetectedCMS}
                  selectOptionArr={detectCMS_Arr}
                  placeholder={t('DetectCMS')}
                  id={'integration__detect-cms'}
                />
              </div>
            </div>

            <div className='normalForm__container1'>
              <div className='normalForm__container1--left'>
                <TrackerSwitch
                  setIsTrackerON={setIsTrackerON}
                  isTrackerON={isTrackerON}
                  isAutofillEnabled={isAutofillEnabled}
                  data={data}
                  setAPIData={setAPIData}
                  setScriptKey={setScriptKey}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ======================================================================= */}
      {isTrackerON && (
        <div
          style={{ marginTop: '2rem' }}
          className='trackingIntegration-wrapper'
        >
          {renderIntegrationCode()}
        </div>
      )}
      {/* ======================================================================= */}
    </div>
  );
}

export default Integration;
