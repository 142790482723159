import { useState } from 'react';
import BasicCssTracking from './BasicCssTracking';
import Tabs from '../../../../components/Tabs/Tabs';
import CustomCssTracking from './CustomCssTracking';

interface Props {
  data: any;
  isAutofillEnabled: boolean;
  isTrackerON: boolean;
  apiKey: string;
  setAPIData: (data: any) => void;
  setScriptKey: (key: string) => void;
}

function CssClasses({
  data,
  isAutofillEnabled,
  isTrackerON,
  apiKey,
  setAPIData,
  setScriptKey,
}: Props) {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const tabItems = ['BasicCssTracking', 'CustomCssTracking'];

  return (
    <div>
      {/* <Tabs
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        tabItems={tabItems}
      /> */}

      {/* {currentTab === 1 ? (
        <CustomCssTracking apiKey={apiKey} />
      ) : (
        <BasicCssTracking
          data={data}
          setAPIData={setAPIData}
          isAutofillEnabled={isAutofillEnabled}
          isTrackerON={isTrackerON}
          setScriptKey={setScriptKey}
        />
      )} */}

      <BasicCssTracking
        data={data}
        setAPIData={setAPIData}
        isAutofillEnabled={isAutofillEnabled}
        isTrackerON={isTrackerON}
        setScriptKey={setScriptKey}
      />
    </div>
  );
}
export default CssClasses;
