import '../../styles/pages/shop/shopPage.scss';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import SubPageContentHolder from '../../components/subPageContentHolder/SubPageContentHolder';
import { classesSVG } from '../../lib/allCustomSVGs';
import CategoryCard from '../../ui/cards/CategoryCard';
import { useTranslation } from 'react-i18next';
import CheckoutTracking from './manageTrackingClasses/CheckoutTracking';
import { useEffect } from 'react';
import PageWrapperMain from '../../components/page-wrappers/PageWrapperMain';
import { allRoutes } from '../../lib/constants/route';

const CheckoutTrackingPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const activeLink = location.pathname.substring(1);
  const navigate = useNavigate();

  useEffect(() => {
    if (activeLink === 'tracking/css-classes/autofill') {
      navigate('/tracking/css-classes/autofill');
    } else if (activeLink === 'tracking/css-classes/integration') {
      navigate('/tracking/css-classes/integration');
    } else if (activeLink === 'tracking/css-classes/trends') {
      navigate('/tracking/css-classes/trends');
    } else if (activeLink === 'tracking/css-classes/custom-events') {
      navigate('/tracking/css-classes/custom-events');
    } else {
      navigate('/tracking/css-classes/manage');
    }
  }, []);

  const checkoutTrackingPageContent = () => {
    return (
      <div className='shopPage'>
        <div className='shopPage__container1'>
          <CategoryCard
            icon={classesSVG}
            title={t('CheckoutTracking')}
            // subTitle={t('CheckoutTracking')}
            link='/tracking/css-classes/manage'
          />
        </div>
      </div>
    );
  };

  return (
    <PageWrapperMain>
      {activeLink === 'tracking' && checkoutTrackingPageContent()}
      <div className='subPage'>
        <SubPageContentHolder>
          <Routes>
            {allRoutes['/tracking/css-classes'] && (
              <Route path='css-classes/*' element={<CheckoutTracking />} />
            )}
          </Routes>
        </SubPageContentHolder>
      </div>
    </PageWrapperMain>
  );
};

export default CheckoutTrackingPage;
