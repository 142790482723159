import React from 'react';
import { useNavigate } from 'react-router-dom';
import { arrowRightThinSVG, filterSVG } from '../../lib/allCustomSVGs';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import SquareGrayButton from '../../ui/buttons/SquareGrayButton';
import '../../styles/components/subPageContentHolder/subPageInnerContentHolder.scss';
import { useTranslation } from 'react-i18next';
import DashboardFilterTime from './DashboardFilterTime';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import DashboardFilterEvent from './DashboardFilterEvent';

interface Props {
  title: string;
  subTitle?: string;
  backBtnLink?: string;
  children: React.ReactNode;
  mainBtnIcon?: any;
  mainBtnTitle?: string;
  mainBtnLink?: string;
  mainBtnAction?: () => void;
  hasFilter?: boolean;
  filterHandler?: () => void;
  isFilterActive?: boolean;
  dashboardEventFilterHandler?: (value: string) => void;
  eventFilter?: string;
  dashboardPeriodFilterHandler?: (value: string) => void;
  timeFilter?: string;
  setCustomPeriodFilterDateRange?: (value: {
    start_date: string | undefined;
    end_date: string | undefined;
  }) => void;
  customPeriodFilterDateRange?: {
    start_date: string | undefined;
    end_date: string | undefined;
  };
}

const SubPageInnerContentHolder = ({
  children,
  title,
  subTitle,
  backBtnLink,
  mainBtnIcon,
  mainBtnTitle,
  mainBtnLink,
  mainBtnAction,
  hasFilter,
  isFilterActive,
  filterHandler,
  dashboardEventFilterHandler,
  eventFilter,
  dashboardPeriodFilterHandler,
  timeFilter,
  setCustomPeriodFilterDateRange,
  customPeriodFilterDateRange,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isSidebarMinimized = useSelector(
    (state: RootState) => state.UI.isSidebarMinimized
  );

  return (
    <div className='subPageInnerContentHolder'>
      <div className='subPageInnerContentHolder__content'>
        <div
          data-is-sidebar-minimized={isSidebarMinimized?.toString()}
          className='subPageInnerContentHolder__content--top'
        >
          <div className='subPageInnerContentHolder__content--top-right'>
            {backBtnLink && (
              <div className='subPageInnerContentHolder__content--top-right-button'>
                <SquareGrayButton
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`${backBtnLink}`);
                  }}
                  type={'button'}
                  icon={arrowRightThinSVG}
                  normal={true}
                  classes='rotate-180-Deg squareGrayButton-mobile-view'
                />
              </div>
            )}
            <div className='subPageInnerContentHolder__content--top-right-title'>
              <h3>{title}</h3>
              {subTitle && <span>{subTitle}</span>}
            </div>
          </div>

          <div className='subPageInnerContentHolder__content--top-buttons'>
            <div className='subPageInnerContentHolder__content--top-buttons-backBtn'>
              {backBtnLink && (
                <SquareGrayButton
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`${backBtnLink}`);
                  }}
                  type={'button'}
                  value={t('GoBack')}
                  icon={arrowRightThinSVG}
                  normal={true}
                  classes='rotate-180-Deg'
                />
              )}
            </div>

            {dashboardEventFilterHandler && eventFilter ? (
              <DashboardFilterEvent
                dashboardEventFilterHandler={dashboardEventFilterHandler}
                eventFilter={eventFilter}
              />
            ) : null}

            {dashboardPeriodFilterHandler &&
            timeFilter &&
            setCustomPeriodFilterDateRange &&
            customPeriodFilterDateRange ? (
              <DashboardFilterTime
                dashboardPeriodFilterHandler={dashboardPeriodFilterHandler}
                timeFilter={timeFilter}
                setCustomPeriodFilterDateRange={setCustomPeriodFilterDateRange}
                customPeriodFilterDateRange={customPeriodFilterDateRange}
              />
            ) : null}

            {mainBtnTitle && (
              <MainSquareButton
                onClick={(e) => {
                  e.preventDefault();
                  if (mainBtnLink) {
                    navigate(`${mainBtnLink}`);
                  }
                  if (mainBtnAction) {
                    mainBtnAction();
                  }
                }}
                type={'button'}
                value={`${mainBtnTitle}`}
                icon={mainBtnIcon}
                normal={true}
              />
            )}
            {hasFilter && (
              <MainSquareButton
                onClick={(e) => {
                  e.preventDefault();
                  if (filterHandler) {
                    filterHandler();
                  }
                }}
                type={'button'}
                value={t('Filter')}
                icon={filterSVG}
                normal={true}
                buttonType={isFilterActive ? 'filter' : 'light'}
              />
            )}
          </div>
        </div>
        {/* <div className='line'>
          <div />
        </div> */}
        <div className='subPageInnerContentHolder__content--bottom'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default SubPageInnerContentHolder;
