import { clearAuthSlice } from '../../store/reducers/authSlice';
import { clearUserSlice } from '../../store/reducers/userSlice';
import store from '../../store/store';

declare const window: any;

function localStorageHardReset() {
  store.dispatch(clearUserSlice());
  store.dispatch(clearAuthSlice());
  window.localStorage.clear();
}

export default localStorageHardReset;
