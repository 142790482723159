import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import get_graph_data_api_call from '../../../lib/api/stats/get_graph_data_api_call';
import ChartWithConfig from '../ChartWithConfig';
import AverageCartValueLineChart from './AverageCartValueLineChart';
import isNotEmptyString from '../../../lib/helper-functions/isNotEmptyString';

type Props = {
  timeFilter: string;
  customPeriodFilterDateRange: {
    start_date: string | undefined;
    end_date: string | undefined;
  };
  eventFilter: string;
};

const MainAverageCartValueChart = ({
  timeFilter,
  customPeriodFilterDateRange,
  eventFilter = 'purchase',
}: Props) => {
  const { t } = useTranslation();
  const stats = useSelector((state: RootState) => state.user?.stats);
  const [params, setParams] = useState<string>(
    `?graph_type=average_cart_value&time_range=${timeFilter}`
  );
  const [response, setResponse] = useState<any>('initial');

  const [title, setTitle] = useState<string>(t('AverageCartValue'));
  const [comment, setComment] = useState<string>(
    t('AverageCartValueLast3MonthsComment')
  );

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    try {
      const res = await get_graph_data_api_call(
        stats.ai_tracker_api_key,
        stats.ai_tracker_access_token,
        params
      );

      if (res) {
        if (res?.period_type) {
          if (res?.period_type === 'month') {
            setComment(t('AverageCartValueLast3MonthsComment'));
            setResponse(res);
          } else if (res?.period_type === 'week') {
            setComment(t('AverageCartValueLast4WeeksComment'));
            setResponse(res);
          } else if (res?.period_type === 'day') {
            setComment(t('AverageCartValueLast7DaysComment'));
            setResponse(res);
          } else {
            setResponse(null);
          }
        } else {
          setResponse(null);
        }
      } else {
        setResponse(null);
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
    }
  };

  useEffect(() => {
    let dateRangeURLParam = '';
    if (timeFilter === 'custom') {
      const { start_date, end_date } = customPeriodFilterDateRange || {};
      if (start_date === undefined || end_date === undefined) {
        return;
      }
      dateRangeURLParam = `&start_date=${start_date}&end_date=${end_date}`;
    }

    setParams(
      `?graph_type=average_cart_value&time_range=${timeFilter}${dateRangeURLParam}`
    );
  }, [timeFilter, customPeriodFilterDateRange]);

  useEffect(() => {
    if (
      stats.ai_tracker_api_key !== '' &&
      stats.ai_tracker_access_token !== ''
    ) {
      setResponse('initial');
      getGraphData();
    }
  }, [params, stats.ai_tracker_access_token, stats.ai_tracker_api_key]);

  return (
    <ChartWithConfig title={title} comment={comment}>
      <AverageCartValueLineChart apiResponse={response} />
    </ChartWithConfig>
  );
};

export default MainAverageCartValueChart;
