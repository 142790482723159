import { useState } from 'react';
import '../../../../styles/pages/checkoutTracking/recommendationItem.scss';
import { useTranslation } from 'react-i18next';
import CustomSVGs from '../../../../ui/customSVGs/CustomSVGs';
import { arrowHeadLeftSVG } from '../../../../lib/allCustomSVGs';
import { AnimatePresence, motion } from 'framer-motion';
import CircularProgress from '@mui/material/CircularProgress';

interface Item {
  id: string;
  property_type: string;
  current_value: string;
  value: string;
  element_id: string;
  title: string;
  description: string;
  type: string;
  is_paused: boolean;
  loadingStatus: 'pending' | 'initial';
}

type Props = {
  data: Item;
  type: 'active' | 'inactive' | 'rejected';
  isDisabled: boolean;
  setRecommendationsHandler: (
    id: string,
    action: 'select' | 'unselect' | 'pause' | 'resume' | 'reject' | 'unarchive',
    type: 'active' | 'inactive' | 'rejected'
  ) => Promise<void>;
};

const RecommendationItem = ({
  data,
  type,
  setRecommendationsHandler,
  isDisabled,
}: Props) => {
  const { t } = useTranslation();
  const [isItemExpanded, setIsItemExpanded] = useState<boolean>(false);

  function toCamelCase(cssProperty: string): string {
    return cssProperty.replace(/-([a-z])/g, (match, letter) =>
      letter.toUpperCase()
    );
  }

  const renderPreview = () => {
    const camelCasedProperty = toCamelCase(data.property_type);
    if (data?.property_type === 'color') {
      return (
        <div className='recommendationItem__bottom--preview-color'>
          <span style={{ backgroundColor: data?.value }}></span>
          <span style={{ color: data?.value }}>{t('Example')}</span>
        </div>
      );
    } else if (
      !data?.property_type?.includes('width') &&
      !data?.property_type?.includes('height') &&
      !data?.property_type?.includes('margin') &&
      !data?.property_type?.includes('position')
    ) {
      return (
        <div
          style={{ [camelCasedProperty]: data?.value }}
          className='recommendationItem__bottom--preview-box'
        >
          {t('Example')}
        </div>
      );
    } else {
      return (
        <div className='recommendationItem__bottom--preview-other'>
          {/* {`${data?.property_type}: ${data?.value}`} */}
          {`${data?.value}`}
        </div>
      );
    }
  };

  const renderCurrentPreview = () => {
    const camelCasedProperty = toCamelCase(data.property_type);
    if (data?.property_type === 'color') {
      return (
        <div className='recommendationItem__bottom--preview-color'>
          <span style={{ backgroundColor: data?.current_value }}></span>
          <span style={{ color: data?.current_value }}>{t('Example')}</span>
        </div>
      );
    } else if (
      !data?.property_type?.includes('width') &&
      !data?.property_type?.includes('height') &&
      !data?.property_type?.includes('margin') &&
      !data?.property_type?.includes('position')
    ) {
      return (
        <div
          style={{ [camelCasedProperty]: data?.current_value }}
          className='recommendationItem__bottom--preview-box'
        >
          {t('Example')}
        </div>
      );
    } else {
      return (
        <div className='recommendationItem__bottom--preview-other'>
          {/* {`${data?.property_type}: ${data?.current_value
}`} */}
          {`${data?.current_value}`}
        </div>
      );
    }
  };

  const renderButton = (
    action: 'resume' | 'pause' | 'select' | 'unselect' | 'reject' | 'unarchive',
    text: string,
    className: string
  ) => {
    if (data?.loadingStatus !== 'pending') {
      return (
        <button
          onClick={() => {
            if (isDisabled) return;
            setRecommendationsHandler(data?.id, action, type);
          }}
          className={`recommendationItem__actions-btn ${className}`}
        >
          {text}
        </button>
      );
    }
  };

  const renderAllButtons = () => {
    if (type === 'inactive') {
      return (
        <>
          {renderButton(
            'reject',
            t('Reject'),
            'recommendationItem__actions-reject-btn'
          )}
          {renderButton(
            'select',
            t('Accept'),
            'recommendationItem__actions-accept-btn'
          )}
        </>
      );
    } else if (type === 'active') {
      return (
        <>
          {data?.is_paused
            ? renderButton(
                'resume',
                t('Resume'),
                'recommendationItem__actions-resume-btn'
              )
            : null}
          {!data?.is_paused
            ? renderButton(
                'pause',
                t('Pause'),
                'recommendationItem__actions-pause-btn'
              )
            : null}
          {renderButton(
            'unselect',
            t('Decline'),
            'recommendationItem__actions-decline-btn'
          )}
          {/*   {renderButton(
            'reject',
            t('Reject'),
            'recommendationItem__actions-reject-btn'
          )} */}
        </>
      );
    } else if (type === 'rejected') {
      return renderButton(
        'unarchive',
        t('Unarchive'),
        'recommendationItem__actions-reject-btn'
      );
    }
  };

  return (
    <div
      data-is-recommendation-item-expanded={isItemExpanded.toString()}
      className='recommendationItem'
    >
      <div className='recommendationItem__top'>
        <div className='recommendationItem__top--title'>
          <h2>{data?.title}</h2>
          <h3>
            {data?.type === 'near_by'
              ? `(${t('NearBy')})`
              : `(${t('NightTime')})`}
          </h3>
        </div>
        <div
          onClick={() => setIsItemExpanded(!isItemExpanded)}
          className='recommendationItem__top--icon'
        >
          <CustomSVGs svg={arrowHeadLeftSVG} />
        </div>
      </div>
      <AnimatePresence>
        {isItemExpanded && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            style={{ overflow: 'hidden' }}
          >
            <div className='recommendationItem__center'>
              <div className='recommendationItem__center--content'>
                <div className='recommendationItem__center--content-description'>
                  <p className='recommendationItem__center--content-description-title'>
                    {`${t('Recommended')}: ${data?.value}`}
                  </p>
                  <span className='recommendationItem__center--content-description-sentence'>
                    {data?.description}
                  </span>
                </div>
              </div>
            </div>
            <div className='recommendationItem__bottom'>
              {data?.current_value ? (
                <div className='recommendationItem__bottom--preview recommendationItem__bottom--preview-current'>
                  <span className='recommendationItem__bottom--preview-text'>{`${t(
                    'Current'
                  )}: `}</span>
                  {renderCurrentPreview()}
                </div>
              ) : null}
              <div className='recommendationItem__bottom--preview'>
                <span className='recommendationItem__bottom--preview-text'>{`${t(
                  'Recommended'
                )}: `}</span>
                {renderPreview()}
              </div>
            </div>
            <div className='recommendationItem__actions'>
              {data?.loadingStatus === 'pending' ? (
                <CircularProgress
                  size={'2.5rem'}
                  style={{
                    color: 'var(--main-text-color)',
                  }}
                />
              ) : null}
              {renderAllButtons()}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default RecommendationItem;
