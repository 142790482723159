import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { crossSVG, infoSVG } from '../../lib/allCustomSVGs';
import '../../styles/inputs/aiTagInput.scss';
import CustomSVGs from '../customSVGs/CustomSVGs';

/* 
---useage in parent component--- 
--Create a function like this and get the values
--------------------------------------------------------
const selectedTages = (tags: string[], id?: string) => {
  console.log(id, tags);
}; 
--------------------------------------------------------
*/

interface Props {
  selectedTags: (input: string[], id: string) => void;
  id: string;
  label: string;
  innerPlaceholder: string;
  required?: boolean;
  initialTags?: string[];
  pattern?: RegExp;
  validationText?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  resetTagIncrement?: number;
  showInfoIcon?: boolean;
}

const AITagInput = ({
  selectedTags,
  id,
  label,
  innerPlaceholder,
  required,
  initialTags,
  pattern,
  validationText,
  autoFocus,
  disabled,
  resetTagIncrement,
  showInfoIcon,
}: Props) => {
  const { t } = useTranslation();
  const [onlyOnFocus, setOnlyOnFocus] = useState<boolean>(false);
  const [isInputInvalid, setIsInputInvalid] = useState<boolean>(false);
  const [tags, setTags] = useState<string[]>(initialTags || []);

  function handleFocusElement(event: React.FocusEvent<HTMLInputElement>) {
    setOnlyOnFocus(true);
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    addTags(e as any);
    setOnlyOnFocus(false);
  };

  const removeTags = (indexToRemove: number) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
    selectedTags(
      tags.filter((_, index) => index !== indexToRemove),
      id
    );
  };

  const addTags = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if ((event.target as HTMLInputElement).value !== '' && !isInputInvalid) {
      setTags([...tags, (event.target as HTMLInputElement).value]);
      selectedTags([...tags, (event.target as HTMLInputElement).value], id);
      (event.target as HTMLInputElement).value = '';
    }
  };

  const validateInput = (value: string) => {
    if (pattern && value !== '') {
      if (pattern.test(value)) {
        setIsInputInvalid(false);
      } else {
        setIsInputInvalid(true);
      }
    } else {
      setIsInputInvalid(false);
    }
  };

  useEffect(() => {
    if (initialTags && initialTags.length > 0) {
      setTags(initialTags);
    }
  }, [initialTags]);

  useEffect(() => {
    if (resetTagIncrement && resetTagIncrement > 0) {
      setTags([]);
      selectedTags([], id);
    }
  }, [resetTagIncrement]);

  return (
    <>
      <div className='aiTagInput-outer'>
        <label
          className={isInputInvalid ? 'label-validation' : ''}
          htmlFor={id}
        >
          {label}
        </label>
        <div
          data-show-info={showInfoIcon ? 'true' : 'false'}
          className='aiTagInput--inner'
        >
          <div className='aiTagInput'>
            <input
              type='text'
              id={id}
              onFocus={handleFocusElement}
              onBlur={handleBlur}
              onChange={(event) =>
                validateInput((event.target as HTMLInputElement).value)
              }
              onKeyUp={(e) => (e.key === 'Enter' ? addTags(e) : null)}
              style={{ fontFamily: 'var(--main-font-secondary)' }}
              placeholder={innerPlaceholder}
              pattern={`${pattern}`}
              required={required}
              title={validationText}
              autoFocus={autoFocus}
              disabled={disabled}
            />
            <ul className='aiTagInput__list'>
              {tags.map((tag: string, index: number) => {
                return (
                  <li key={index} className='aiTagInput__list--item'>
                    <span className='aiTagInput__list--item-title'>{tag}</span>
                    <div
                      title='Remove Tag'
                      onClick={() => {
                        removeTags(index);
                      }}
                      className='aiTagInput__list--item-icon'
                    >
                      <CustomSVGs svg={crossSVG} />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div id={`${id}-info-text`} className='aiTagInput--inner-info'>
            <CustomSVGs svg={infoSVG} />
          </div>
        </div>
      </div>
      <div className='aiTagInput-validation-text'>
        {onlyOnFocus && validationText ? validationText : ''}
      </div>
    </>
  );
};

export default AITagInput;
