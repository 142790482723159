import '../../styles/cards/statusCard.scss';
import CustomSVGs from '../customSVGs/CustomSVGs';

interface Props {
  title: string;
  info: string;
  description: string;
  color?: string;
  icon: any;
}

const StatusCard = ({ title, info, description, color, icon }: Props) => {
  return (
    <div className='statusCard'>
      <div className='statusCard__icon'>
        <CustomSVGs svg={icon} />
      </div>
      <div className='statusCard__content'>
        <span>{title}</span>
        <h2
          style={{
            color: color === 'green' ? '#28a745' : 'var(--main-text-color)',
          }}
        >
          {info}
        </h2>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default StatusCard;
