import { applicationWWWXRLEncodedBodyBuilder } from '../utils/body-builder';
import { asyncHandler } from './async-handler';
import { RequestInfo } from '../../interfaces/RequestInfo';
import store from '../../store/store';
import { setAccessToken } from '../../store/reducers/authSlice';
import moment from 'moment';
import {
  updateOnboardingStage,
  updateProfile,
} from '../../store/reducers/userSlice';
import { REGISTRATION_API } from '../constants/central-api';
import { submitLogToBugsnag } from './log';
import { stepsAndStagesMap } from '../constants/steps_stages_map';

type RegistrationData = {
  email: string;
  password: string;
};

export async function requestUserSignUp(registrationData: RegistrationData) {
  const merchantSlug = store.getState().user.merchant.merchant_slug;
  const appLanguage = store.getState().app.language;

  try {
    const requestInfo: RequestInfo = {
      url: REGISTRATION_API(merchantSlug),
      method: 'POST',
      body: applicationWWWXRLEncodedBodyBuilder(registrationData),
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
      },
    };

    const actionType = 'REGISTRATION_VERIFICATION';
    const customMsg = 'Registration Successful';

    const res: any = await asyncHandler(
      requestInfo,
      actionType,
      customMsg,
      false
    );

    const response = res.data;

    if (res.actionType === actionType && response.success) {
      const token_type = 'Bearer';
      const current_step = 'step_1';
      const { access_token, user } = response?.data;

      //TODO must check if this "role" check is needed anymore
      if (user?.role[0]?.name === 'seller') {
        if (access_token && access_token !== '') {
          // Set the access token
          store.dispatch(
            setAccessToken({
              sellerToken: access_token,
              type: token_type,
              expiresOn: moment().add(21, 'days'),
            })
          );

          if (user?.seller_id) {
            // set user profile data
            store.dispatch(
              updateProfile({
                seller_id: user.seller_id,
                email: registrationData.email,
                language: appLanguage,
              })
            );

            store.dispatch(
              updateOnboardingStage(stepsAndStagesMap[current_step].name)
            );
          }
        }
      }
    } else {
      console.log(response.message);
      return false;
    }
  } catch (exception) {
    console.log('Error during authentication request');
    submitLogToBugsnag('error', `${exception}`);
    return false;
  }
}
