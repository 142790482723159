import store from '../../store/store';
import { useEffect, useState } from 'react';
import '../../styles/pages/signup.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import RenderLoginPageLogo from '../login/RenderLoginPageLogo';
import { requestUserSignUp } from '../../lib/api/sign-up';
import { AnimatePresence, motion } from 'framer-motion';
import { pageSlideOutForward } from '../../framer-animations/page-slide-in-out';
import FooterLinks from '../../components/footer/FooterLinks';
import useTitle from '../../customHooks/useTitle';
import AINormalInput from '../../ui/inputs/AINormalInput';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { crossSVG, tickSVG } from '../../lib/allCustomSVGs';
import { clearUserSlice, setReferral } from '../../store/reducers/userSlice';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { useGoogleLogin } from '@react-oauth/google';
import { asyncHandler } from '../../lib/api/async-handler';
import { submitLogToBugsnag } from '../../lib/api/log';
import { LOGIN_API } from '../../lib/constants/central-api';
import { applicationWWWXRLEncodedBodyBuilder } from '../../lib/utils/body-builder';
import { setIsInitSuccess, setMessage } from '../../store/reducers/appSlice';
import isNotEmptyString from '../../lib/helper-functions/isNotEmptyString';
import { setAccessToken } from '../../store/reducers/authSlice';
import moment from 'moment';
import { HOME_ROUTE } from '../../lib/constants/route';
import { fetchAppInit } from '../../lib/api/app-init';
import googleButtonImg from '../../assets/googleIcon.png';

const SignUpPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useTitle(t('Register'));
  const authToken = store.getState().auth.authorization.accessToken;
  // const [googleLoading, setGoogleLoading] = useState<boolean>(false);

  const referral = useSelector((state: RootState) => state.user?.referral);

  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  const merchantName = useSelector(
    (state: RootState) => state.user?.merchant?.config?.merchant_name
  );
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const isDarkMode = useSelector((state: RootState) => state.UI.theme.dark);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordInputFocused, setIsPasswordInputFocused] = useState(false);
  const [loading, setLoading] = useState(false);

  const [hasUppercase, setHasUppercase] = useState<boolean>(false);
  const [hasLowercase, setHasLowercase] = useState<boolean>(false);
  const [hasNumber, setHasNumber] = useState<boolean>(false);
  const [hasSpecialChar, setHasSpecialChar] = useState<boolean>(false);
  const [isLongEnough, setIsLongEnough] = useState<boolean>(false);

  const validatePassword = (password: string) => {
    setHasUppercase(/[A-Z]/.test(password));
    setHasLowercase(/[a-z]/.test(password));
    setHasNumber(/[0-9]/.test(password));
    setHasSpecialChar(/[^a-zA-Z0-9]/.test(password));
    setIsLongEnough(password.length >= 8);
  };

  const showPasswordValidationView =
    !hasUppercase ||
    !hasLowercase ||
    !hasNumber ||
    !hasSpecialChar ||
    !isLongEnough;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  useEffect(() => {
    // Check if the referral exists in the URL
    const params = new URLSearchParams(window.location.search);
    const referralParam = params.get('referral');

    // If referral exists in URL and it's not already in Redux, update Redux state
    if (referralParam && typeof referralParam === 'string') {
      dispatch(setReferral(referralParam));
    } else {
      dispatch(setReferral(''));
    }
  }, []);

  //getting cms or psp types from params and saving them in store
  /*   useEffect(() => {
    const params = window.location.search;
    if (params.includes('cms') && params.includes('psp')) {
      const str = params.substring(1).toLowerCase();
      const strArr = str.split('&');
      strArr.forEach((el: string) => {
        if (el.substring(0, 3) === 'cms') {
          const foundCMS = el.substring(4);
          console.log(`found cms: ${foundCMS}`);
          dispatch(setPreSelectedCMS_PSP({ type: 'cms', name: foundCMS }));
        } else if (el.substring(0, 3) === 'psp') {
          const foundPSP = el.substring(4);
          console.log(`found psp: ${foundPSP}`);
          dispatch(setPreSelectedCMS_PSP({ type: 'psp', name: foundPSP }));
        }
      });
    } else if (params.includes('cms')) {
      const str = params.substring(1).toLowerCase();
      const foundCMS = str.substring(4);
      console.log(`found cms: ${foundCMS}`);
      dispatch(setPreSelectedCMS_PSP({ type: 'cms', name: foundCMS }));
    } else if (params.includes('psp')) {
      const str = params.substring(1).toLowerCase();
      const foundPSP = str.substring(4);
      console.log(`found psp: ${foundPSP}`);
      dispatch(setPreSelectedCMS_PSP({ type: 'psp', name: foundPSP }));
    }
  }, []); */

  const isDisabled =
    !hasUppercase ||
    !hasLowercase ||
    !hasNumber ||
    !hasSpecialChar ||
    !isLongEnough ||
    email === '' ||
    !/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/.test(email);

  async function handleSignUpClick(e: any) {
    setLoading(true);
    e.preventDefault();

    // clear all existing user data before sign up
    dispatch(clearUserSlice());

    const sendingData: { email: string; password: string; [key: string]: any } =
      {
        email: email,
        password: password,
      };

    if (referral && referral !== '') {
      sendingData.is_referred = 1;
      sendingData.referral_code = referral;
    }

    const isRegistrationSuccess = await requestUserSignUp(sendingData);

    if (!isRegistrationSuccess) {
      setLoading(false);
    }
  }

  /* async function googleSignIn(e: any) {
    e.preventDefault();
    setGoogleLoading(true);
    try {
      const requestInfo: RequestInfo = {
        url: GOOGLE_LOGIN_ENDPOINT,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      const actionType = 'GOOGLE_LOGIN_VERIFICATION';
      const customMsg = 'Redirecting to Google Login.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success === true) {
        const { google_url } = response.result;
        window.open(`${google_url}`, '_self');
      } else {
        setGoogleLoading(false);

        console.log(response);
      }
    } catch (exception) {
      setGoogleLoading(false);
      console.log('Error during authentication request');
    }
  } */

  /* ============= Google OAuth Sign In start ================ */
  const normalLoginProcess = (response: any, isWebAuthnLogin?: boolean) => {
    store.dispatch(setIsInitSuccess(false));
    const token_type = 'Bearer';
    const { access_token } = response?.data;

    if (isNotEmptyString(access_token)) {
      store.dispatch(
        setAccessToken({
          sellerToken: access_token,
          type: token_type,
          expiresOn: moment().add(21, 'days'),
        })
      );
      navigate(HOME_ROUTE);

      //make init call to get all info about logged-In user
      fetchAppInit();
    } else {
      console.log('Access Token is empty');
      submitLogToBugsnag('error', `Access Token is empty`);
      store.dispatch(
        setMessage({
          message: t('UnauthorizedAccess'),
          messageType: 'error',
        })
      );
    }
  };
  const googleSignIn = async (googleAccessToken: any) => {
    try {
      const sendingData: {
        oauth: string;
        role: string;
        token: string;
        [key: string]: any;
      } = {
        oauth: 'google',
        role: 'seller',
        token: googleAccessToken,
      };

      if (referral && referral !== '') {
        sendingData.is_referred = 1;
        sendingData.referral_code = referral;
      }

      const requestInfo: RequestInfo = {
        url: LOGIN_API,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder(sendingData),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
        },
      };

      const actionType = 'LOGIN_VERIFICATION';
      const customMsg = t('LoginSuccess-msg');

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false,
        true
      );

      const response = res.data;

      if (res.actionType === actionType && response.success === true) {
        normalLoginProcess(response);
      } else {
        store.dispatch(
          setMessage({
            message: response.message,
            messageType: 'error',
          })
        );
      }
    } catch (exception) {
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  };

  const googleAuthentication = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      googleSignIn(tokenResponse?.access_token);
    },
  });
  /* ============= Google OAuth Sign In end ================ */

  const passwordValidationView = () => {
    return (
      <motion.div
        initial={{ opacity: 0.1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0.1 }}
        transition={{ duration: 0.3 }}
        className='signup__right--validation'
      >
        <div className='signup__right--validation-item'>
          <div className='signup__right--validation-item-icon'>
            {hasUppercase ? (
              <CustomSVGs fillColor={'#28a745'} svg={tickSVG} />
            ) : (
              <div className='signup__right--validation-item-icon-x'>
                <div className='signup__right--validation-item-icon-x'>
                  <CustomSVGs fillColor={'#dc3545'} svg={crossSVG} />
                </div>
              </div>
            )}
          </div>
          <p>{t('AtLeastOneUppercaseLetter')}</p>
        </div>
        <div className='signup__right--validation-item'>
          <div className='signup__right--validation-item-icon'>
            {hasLowercase ? (
              <CustomSVGs fillColor={'#28a745'} svg={tickSVG} />
            ) : (
              <div className='signup__right--validation-item-icon-x'>
                <CustomSVGs fillColor={'#dc3545'} svg={crossSVG} />
              </div>
            )}
          </div>
          <p>{t('AtLeastOneLowercaseLetter')}</p>
        </div>
        <div className='signup__right--validation-item'>
          <div className='signup__right--validation-item-icon'>
            {hasNumber ? (
              <CustomSVGs fillColor={'#28a745'} svg={tickSVG} />
            ) : (
              <div className='signup__right--validation-item-icon-x'>
                <CustomSVGs fillColor={'#dc3545'} svg={crossSVG} />
              </div>
            )}
          </div>
          <p>{t('AtLeastOneNumber')}</p>
        </div>
        <div className='signup__right--validation-item'>
          <div className='signup__right--validation-item-icon'>
            {hasSpecialChar ? (
              <CustomSVGs fillColor={'#28a745'} svg={tickSVG} />
            ) : (
              <div className='signup__right--validation-item-icon-x'>
                <CustomSVGs fillColor={'#dc3545'} svg={crossSVG} />
              </div>
            )}
          </div>
          <p>{t('AtLeastOneSpecialCharacter')}</p>
        </div>
        <div className='signup__right--validation-item'>
          <div className='signup__right--validation-item-icon'>
            {isLongEnough ? (
              <CustomSVGs fillColor={'#28a745'} svg={tickSVG} />
            ) : (
              <div className='signup__right--validation-item-icon-x'>
                <CustomSVGs fillColor={'#dc3545'} svg={crossSVG} />
              </div>
            )}
          </div>
          <p>{t('AtLeast8Characters')}</p>
        </div>
      </motion.div>
    );
  };

  const signupView = () => {
    return (
      <motion.div {...pageSlideOutForward} className='signup'>
        {/* <div className='signup__left'>
          <div className='signup__left--content'>
            <h1>
              {t('WelcomeTo')}{' '}
              {merchantName && merchantName !== '' ? merchantName : 'AdUp'}
            </h1>

            <div className='signup__left--image'>
              {merchantTheme?.merchant_logo_light &&
              merchantTheme?.merchant_logo_light !== '' ? (
                <img
                  src={`${staticURL}/medium/${
                    isDarkMode
                      ? merchantTheme?.merchant_logo_light
                      : merchantTheme?.merchant_logo_dark
                  }`}
                  alt={'logo'}
                />
              ) : (
                <img
                  src={isDarkMode ? adUpLogoLight : adUpLogoDark}
                  alt={'logo'}
                />
              )}
            </div>
          </div>
        </div> */}

        <form onSubmit={handleSignUpClick} className='signup__right'>
          {/* <div className='signup__logo'>
            <RenderLoginPageLogo
              staticURL={staticURL}
              merchantTheme={merchantTheme}
              isDarkMode={isDarkMode}
            />
          </div> */}
          <div className='signup__content'>
            <div className='signup__right--top'>
              <div className='signup__right--top-logo'>
                <RenderLoginPageLogo
                  staticURL={staticURL}
                  merchantTheme={merchantTheme}
                  isDarkMode={isDarkMode}
                />
              </div>
              <h1>{t('YourAlAgentTARAIsHere')}</h1>
              <p>{t('ChatWithYourData')}</p>
            </div>
            <div className='signup__right--center'>
              <AINormalInput
                id='sign-up-form-email'
                value={email}
                type='email'
                changeListeners={[(e) => setEmail(e.target.value)]}
                placeholder={t('EnterYourEmailAddress')}
                innerPlaceholder={'you@example.com'}
                required={true}
                pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
                title={t('Email-validation')}
              />
              <AINormalInput
                id='sign-up-form-password'
                value={password}
                changeListeners={[(e) => handleChange(e)]}
                placeholder={t('Password')}
                type='password'
                innerPlaceholder={t('ChooseAPassword')}
                required={true}
                focusListeners={[
                  (e) => {
                    setIsPasswordInputFocused(true);
                  },
                ]}
                blurListeners={[
                  (e) => {
                    setIsPasswordInputFocused(false);
                  },
                ]}
              />
            </div>
            <AnimatePresence exitBeforeEnter>
              {isPasswordInputFocused &&
                showPasswordValidationView &&
                passwordValidationView()}
            </AnimatePresence>
            <br />
            <div className='signup__right--bottom'>
              <div className='signup__right--bottom-button'>
                <MainSquareButton
                  value={t('Continue')}
                  type='submit'
                  loading={loading}
                  disabled={isDisabled}
                  classes='mainSquareButton-extra-1'
                />
              </div>

              <div className='login__right--bottom-privacy'>
                <br />
                ⎯⎯⎯⎯⎯⎯⎯⎯⎯ {t('Or')} ⎯⎯⎯⎯⎯⎯⎯⎯⎯
              </div>

              <div className='login__right--other'>
                <div className='login__right--bottom-button'>
                  <MainSquareButton
                    value={t('ContinueWithGoogle')}
                    image={googleButtonImg}
                    type='button'
                    onClick={() => {
                      googleAuthentication();
                    }}
                  />
                </div>
              </div>
              <br />
              <div className='signup__right--bottom-links'>
                <FooterLinks isRegisterPage={true} />

                <h2>
                  {t('Or')}{' '}
                  <span onClick={() => navigate('/login')}>{t('SignIn')} </span>
                  {t('YourAccount')}
                </h2>
              </div>
            </div>
          </div>
        </form>
      </motion.div>
    );
  };

  if (!authToken.sellerToken) {
    return signupView();
  } else {
    window.location.assign('/');
    return null;
  }
};

export default SignUpPage;
